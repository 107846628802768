import React, { useContext, useEffect } from "react";
import Image from "./Image";
import { PhotoContext } from "../context/PhotoContext";
import Loader from "./Loader";
import { MetaMaskButton } from "./MetaMaskButton";
import { Link } from "react-router-dom";

const Upload = ({ squareKey }) => {
  const { getSquareByKey, selectedImage } = useContext(PhotoContext);
  useEffect(() => {
    if (squareKey) {
      getSquareByKey(squareKey);
    }
    // eslint-disable-next-line
  }, [squareKey]);

  const refreshImage = () => {
    getSquareByKey(squareKey);
  };

  return !selectedImage ? (
    <Loader />
  ) : (
    <div className="pageCenter">
      <div className="viewImage">
        <div className="backButton">
          <Link to={"/"}>← Back to the squares</Link>
        </div>
        <div className="viewImageText">
          <h1>Congratulations!</h1>
          <h2>This is your own BlackSquare.</h2>
          <div>
            Now it's your choice what to do with it. You can leave it blank or
            upload any image you want!
          </div>
          <div>
            Before uploading an image, check that you have the rights to use it.
            NSFW images are not allowed and will be deleted. Image should be
            200x200 pixels and less than 100KB. Allowed formats: JPG, PNG.
          </div>
          <div>
            If your image breaks any of the rules, it will be deleted, and you
            will not be able to upload another one.
          </div>
        </div>
        <div className="itemList">
          <Image
            url={selectedImage.pictureUrl}
            key={selectedImage.id}
            hasSelected={false}
            x={selectedImage.x}
            y={selectedImage.y}
            nftUrl={selectedImage.nftUrl}
          ></Image>

          <MetaMaskButton
            squareKey={selectedImage.key}
            refreshImage={refreshImage}
          ></MetaMaskButton>
        </div>
      </div>
    </div>
  );
};

export default Upload;
