import React from "react";
import NoImages from "./NoImages";
import Image from "./Image";
const Gallery = (props) => {
  const results = props.data;
  const selected = props.selected;
  let images;
  let noImages;
  // map variables to each item in fetched image array and return image component
  if (results.length > 0) {
    images = results.map((image) => {
      return (
        <Image
          url={image.pictureUrl}
          key={image.id}
          squareKey={selected && selected.id === image.id ? selected.key : null}
          selected={selected && selected.id === image.id ? true : false}
          hasSelected={selected !== null ? true : false}
          preview={props.preview}
          x={image.x}
          y={image.y}
          nftUrl={
            image.identifier
              ? "https://opensea.io/assets/" +
                image.chain +
                "/" +
                image.contract +
                "/" +
                image.identifier +
                "/"
              : null
          }
          number={results.indexOf(image)}
        />
      );
    });
  } else {
    noImages = <NoImages />; // return 'not found' component if no images fetched
  }
  return (
    <div>
      <div className="itemList">{images}</div>
      {noImages}
    </div>
  );
};

export default Gallery;
