import React, { useContext, useEffect } from "react";
import { PhotoContext } from "../context/PhotoContext";
import Gallery from "./Gallery";
import Loader from "./Loader";
import { Link } from "react-router-dom";

const Container = ({ squareKey, preview }) => {
  const {
    images,
    selectedImage,
    loading,
    runSearch,
    getSquareByKey,
    setSelectedImage,
  } = useContext(PhotoContext);
  useEffect(() => {
    runSearch((imagesData) => {
      if (preview) {
        setSelectedImage(imagesData.find((image) => image.id === squareKey));
      }
    });

    if (squareKey && !preview) {
      getSquareByKey(squareKey);
    }

    // eslint-disable-next-line
  }, [squareKey]);

  return (
    <div className="photo-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Gallery data={images} selected={selectedImage} preview={preview} />
          <div className="about">
            <Link to={"/about"}>What is it?</Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Container;
