import React from "react";
import { Link } from "react-router-dom";

const About = ({}) => {
  return (
    <div style={{ height: "100vh", marginTop: 150 }}>
      <div className="backButton">
        <Link to={"/"}>← Back to the squares</Link>
      </div>
      <h1>BlackSquares: An Intersection of Art and Blockchain</h1>

      <div
        style={{
          backgroundImage: "url(Image_14_14.png)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: 200,
          margin: "auto",
        }}
      ></div>

      <h2>What is BlackSquares?</h2>

      <p>
        <strong>BlackSquares</strong> is a groundbreaking digital art
        installation developed by a collective of modern artists. This unique
        project seamlessly merges the world of art with cutting-edge blockchain
        technology, allowing participants to intertwine their personal
        creativity with a public art piece.
      </p>

      <div
        style={{
          backgroundImage: "url(sign.png)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: 200,
          margin: "auto",
        }}
      ></div>

      <h2>How does it work?</h2>
      <ol>
        <li>
          <strong>Purchase an NFT</strong>: Before you can upload an image to
          the BlackSquares platform, you must own the associated Non-Fungible
          Token (NFT). These tokens represent a unique piece of the digital
          canvas and ensure that every uploaded image is authentic and original.
        </li>
        <li>
          <strong>Upload your Image</strong>: Once you have secured your digital
          real estate, you can upload any image of your choice, which will then
          be displayed within a small square on the website. The juxtaposition
          of myriad images uploaded by users worldwide crafts a captivating
          mosaic of human expression.
        </li>
        <li>
          <strong>Be a Part of a Digital Tapestry</strong>: As more users upload
          their images, the BlackSquares evolves, becoming a living testament to
          global collaboration and the democratization of modern art.
        </li>
      </ol>

      <h2>Features</h2>
      <ul>
        <li>
          <strong>Immutable</strong>: Thanks to blockchain technology, once an
          image is uploaded, its place in the BlackSquares is eternal. Your art,
          memories, and moments will forever be a part of this digital canvas.
        </li>
        <li>
          <strong>Interactive</strong>: Explore the ever-growing collection of
          images. Zoom in to see each square's detail or zoom out to appreciate
          the collective masterpiece.
        </li>
        <li>
          <strong>Community-Driven</strong>: The value and beauty of
          BlackSquares grow with each new addition. As an owner of a
          BlackSquares NFT, you become a part of a global community of artists,
          dreamers, and innovators.
        </li>
      </ul>

      <h2>Why BlackSquares?</h2>
      <p>
        The name <strong>BlackSquares</strong> is a nod to the avant-garde art
        of the 20th century, recalling Kazimir Malevich's iconic work, "Black
        Square." Just as Malevich's painting pushed boundaries and challenged
        conventions in its time, BlackSquares aspires to do the same in the
        digital realm.
      </p>

      <h2>Exhibitions &amp; Events (soon)</h2>
      <p>
        <strong>BlackSquares Live</strong>: Join us for monthly virtual tours
        and artist spotlight sessions where we discuss the stories behind some
        of the most captivating squares.
      </p>
      <p>
        <strong>BlackSquares Global</strong>: Annual events held in various
        cities showcasing a physical representation of the digital canvas,
        bringing the online world offline.
      </p>

      <h2>Become a Part of the Legacy</h2>
      <p>
        The beauty of BlackSquares lies not just in the individual images but in
        the collective power of community-driven art. Whether you're an artist,
        a crypto-enthusiast, or simply someone with a story to share,
        BlackSquares welcomes you.
      </p>
      <p>
        <strong>
          Join us today and etch your story into the annals of digital art
          history.
        </strong>
      </p>
      <div style={{ height: 20 }}></div>
    </div>
  );
};

export default About;
