import React, { Component } from "react";
import PhotoContextProvider from "./context/PhotoContext";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Item from "./components/Item";
import Upload from "./components/Upload";
import NotFound from "./components/NotFound";
import About from "./components/About";

class App extends Component {
  render() {
    return (
      <PhotoContextProvider>
        <BrowserRouter basename="/">
          <div className="container">
            <Route render={(props) => <></>} />
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Item squareKey={undefined} />}
              />
              <Route
                path="/view/:squareKey"
                render={(props) => {
                  return <Item squareKey={props.match.params.squareKey} />;
                }}
              />
              <Route
                path="/preview/:squareKey"
                render={(props) => {
                  return (
                    <Item
                      squareKey={props.match.params.squareKey}
                      preview={true}
                    />
                  );
                }}
              />
              <Route
                path="/upload/:squareKey"
                render={(props) => (
                  <Upload squareKey={props.match.params.squareKey} />
                )}
              />
              <Route path="/about" render={(props) => <About />} />

              <Route component={NotFound} />
            </Switch>
          </div>
        </BrowserRouter>
      </PhotoContextProvider>
    );
  }
}

export default App;
