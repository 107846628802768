import React from "react";
import { Link } from "react-router-dom";

const Image = ({
  url,
  id,
  selected,
  hasSelected,
  x,
  y,
  nftUrl,
  squareKey,
  number,
  preview,
}) => {
  let image;
  let urlComponent;
  if (url === undefined || url === null || url === "") {
    image = (
      <div
        className="noImageYet"
        style={{ animationDelay: `${number * 0.01}s` }}
      >
        {x + 1} × {y + 1}
      </div>
    );
  } else {
    image = (
      <div
        style={{
          backgroundImage: "url(" + url + ")",
          animationDelay: `${number * 0.01}s`,
        }}
      ></div>
    );
  }

  if (selected) {
    if (preview) {
      urlComponent = (
        <a target="_blank" rel="noreferrer" href={nftUrl}>
          {image}
        </a>
      );
    } else {
      urlComponent = <Link to={"/upload/" + squareKey}>{image}</Link>;
    }
  } else if (!hasSelected) {
    urlComponent = (
      <a target="_blank" rel="noreferrer" href={nftUrl}>
        {image}
      </a>
    );
  } else {
    urlComponent = <>{image}</>;
  }

  return (
    <div
      style={{}}
      className={
        "listItem " + (selected ? "selected" : hasSelected ? "unselected" : "")
      }
    >
      {urlComponent}
    </div>
  );
};

export default Image;
