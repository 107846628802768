import React, { createContext, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
export const PhotoContext = createContext();

const PhotoContextProvider = (props) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  //const baseApiUrl = "http://localhost:5120";
  const baseApiUrl = "";

  const runSearch = (promise) => {
    axios
      .get(baseApiUrl + `/api/Items`)
      .then((response) => {
        setImages(response.data);
        setLoading(false);
        if (promise) {
          promise(response.data);
        }
      })
      .catch((error) => {
        console.log(
          "Encountered an error with fetching and parsing data",
          error
        );
      });
  };
  const getSquareByKey = (key) => {
    axios
      .get(baseApiUrl + `/api/Items/GetByKey/${key}`)
      .then((response) => {
        setSelectedImage(response.data);
      })
      .catch((error) => {
        console.log(
          "Encountered an error with fetching and parsing data",
          error
        );
      });
  };
  const uploadPhoto = (formData, promise) => {
    axios
      .post(baseApiUrl + `/api/Items/Upload/`, formData)
      .then((response) => {
        setUploadMessage(response.data);
        if (promise) {
          promise();
        }
      })
      .catch((error) => {
        setUploadMessage(error.response.data);
      });
  };
  return (
    <PhotoContext.Provider
      value={{
        images,
        selectedImage,
        setSelectedImage,
        loading,
        runSearch,
        getSquareByKey,
        uploadPhoto,
        uploadMessage,
      }}
    >
      {props.children}
    </PhotoContext.Provider>
  );
};

export default PhotoContextProvider;
