import MetaMaskOnboarding from "@metamask/onboarding";
import React, { useContext, useEffect } from "react";
import { PhotoContext } from "../context/PhotoContext";
import { Buffer } from "buffer";

const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect";
const CONNECTED_TEXT = "Connected";

export function MetaMaskButton(props) {
  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [errorText, setErrorText] = React.useState("");
  const [isDisabled, setDisabled] = React.useState(false);

  const [accounts, setAccounts] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const onboarding = React.useRef();

  const { uploadPhoto, uploadMessage } = useContext(PhotoContext);

  const { squareKey, refreshImage } = props;

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
      }
    }
  }, [accounts]);

  useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }

    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(handleNewAccounts);
      window.ethereum.on("accountsChanged", handleNewAccounts);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleNewAccounts);
      };
    }
  }, []);

  const onClick = () => {
    try {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((newAccounts) => setAccounts(newAccounts));
      } else {
        onboarding.current.startOnboarding();
      }
    } catch (err) {
      setErrorText("Error during wallet connection");
    }
  };

  const onFileChange = (event) => {
    setErrorText("");
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = async () => {
    setErrorText("");
    try {
      const from = accounts[0];
      // For historical reasons, you must submit the message to sign in hex-encoded UTF-8.
      // This uses a Node.js-style buffer shim in the browser.
      const sign = await window.ethereum.request({
        method: "personal_sign",
        params: [
          "0x" +
            Buffer.from("Uploading image for " + squareKey, "utf8").toString(
              "hex"
            ),
          from,
        ],
      });

      console.log(
        Buffer.from("Uploading image for " + squareKey, "utf8").toString("hex")
      );

      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append("file", selectedFile, selectedFile.name);
      formData.append("squareKey", squareKey);
      formData.append("sign", sign);

      uploadPhoto(formData, refreshImage);

      setSelectedFile(null);
    } catch (err) {
      console.log(err);
      setErrorText("Error during request signing");
    }

    // Request made to the backend api
    // Send formData object
    //axios.post("api/uploadfile", formData);
  };

  return (
    (!isDisabled && (
      <div className="uploadButtons">
        <button disabled={isDisabled} onClick={onClick}>
          {buttonText}
        </button>
      </div>
    )) ||
    (isDisabled && (
      <div className="uploadButtons">
        <input type="file" accept=".jpg,.png" onChange={onFileChange} />
        <button disabled={!selectedFile} onClick={onFileUpload}>
          Sign and upload
        </button>
        <div>
          Make sure you are signing with the same account where you have your
          NFT
        </div>
        <div style={{ color: "red" }}>
          {errorText}
          {uploadMessage}
        </div>
      </div>
    ))
  );
}
