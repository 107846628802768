import React from "react";
import Container from "./Container";

const Item = ({ squareKey, preview }) => {
  return (
    <div>
      <Container squareKey={squareKey} preview={preview} />
    </div>
  );
};

export default Item;
